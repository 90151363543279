import { NgModule } from '@angular/core';

/**
 * @deprecated You no longer need to import the `StorageServiceModule`, since the `SESSION_STORAGE` and `LOCAL_STORAGE` injection tokens are
 * now 'self providing' in the root injector.
 */
@NgModule()
export class StorageServiceModule {

}
